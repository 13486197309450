import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home';
import Contact from './components/contact';
import Ons from './components/ons';
import Gallery from './components/gallery';
import Tarieven from './components/tarieven';
import Nav from './components/nav';
import Footer from './components/footer';
import Totop from './totop';
import CookieConsent from "react-cookie-consent";
import Alert from "react-bootstrap/Alert";

function App() {
  return (
    <Router>
        <div className="App">
          <Nav/>
          <Switch>
            <Totop>
              <Route path="/" exact component={Home}/>
              <Route path="/home" exact component={Home}/>
              <Route path="/contact" exact component={Contact}/>
              {/*<Route path="/over-ons" exact component={Ons}/>*/}
              <Route path="/galerij" exact component={Gallery}/>
              <Route path="/tarieven" exact component={Tarieven}/>
            </Totop>
          </Switch>
          <CookieConsent location="bottom"
              buttonText="Oké"
              enableDeclineButton
              declineButtonText="Nee, bedankt"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#505050" }}
              buttonStyle={{ background: "#1352a1", color: "white",paddingLeft:"3rem" ,paddingRight:"3rem"}}
              declineButtonStyle={{background: "#A7A6A6"}}
              expires={150}
              flipButtons= "true"
              >
              <p className="cookie-text m-0">Deze website maakt gebruik van cookies</p>
          </CookieConsent>
          <Footer/>
        </div>
      </Router>
  );
}

export default App;
