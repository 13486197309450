import React from "react";
import "../App";
import { Fade, Slide } from "react-reveal";
import { Col, Container, Button, Jumbotron, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Certified from "../assets/images/svg/user-check-solid.svg";
import Tools from "../assets/images/svg/tools-solid.svg";
import Care from "../assets/images/svg/hand-holding-heart-solid.svg";
import Apk from "../assets/images/333.jpg";
import Uitlezen from "../assets/images/555.jpg";
import Onderzoek from "../assets/images/working2.png";

function Home() {
  return (
    <div className="Home">
      <Slide top duration={1600}>
        <Col lg={12} className="header home">
          <Col lg={12} className="header-inner">
            <h1 className="header-text">MB Garant</h1>
            <p className="header-text">
              reparatie | onderhoud | service | diagnose | apk
            </p>
          </Col>
        </Col>
      </Slide>

      {/*inside**/}

      <div>
        <Fade duration={800} delay={700}>
          <Container className="pb-5 pt-3">
            <Col lg={11} className={"mx-auto text-center"}>
              <div>
                <h2>Wij staan klaar voor u en uw auto</h2>
              </div>
              <hr />
            </Col>

            <Col lg={10} className="col-lg-10 home-icons mx-auto pt-5 row">
              <Col lg={4} className="pt-4">
                <img src={Certified} className="ml-4" alt="Certified" />
                <p className="mt-3">Gecertificeerde monteurs</p>
                <hr className="thicc-sub" />
              </Col>

              <Col lg={4}>
                <img src={Tools} alt="tools" />
                <p className="mt-3">
                  MB Garant is dé garage voor onderhoud en reparaties aan uw
                  auto
                </p>
                <hr className="thicc-sub" />
              </Col>

              <Col lg={4} className="pt-4">
                <img src={Care} className="ml-2" alt="Care" />
                <p className="mt-3">De beste zorg voor uw auto</p>
                <hr className="thicc-sub" />
              </Col>
            </Col>
          </Container>
        </Fade>
      </div>

      {/*◇─◇──◇────◇────◇──── service ────◇────◇────◇──◇─◇*/}

      <div>
        <div className="home-services my-4 white-text">
          <Row lg={3} className="mx-auto justify-center py-3 pb-3 pt-5">
            <Col className="mx-auto">
              <hr className="thicc hr-white mb-1" />
            </Col>
            <Col lg={12} className=" mx-auto">
              <h1>Onze services</h1>
            </Col>
          </Row>

          <Row className="mx-auto justify-content-around pt-5 pb-5 text-center w-75">
            <div className="col-lg-3 service">
              <p>Apk</p>
              <hr />
              <Col id="wrap-1">
                <img src={Apk} className="img-fluid" alt="apk" />
                <div class="overlay">
                  <p>Vanaf €28,93</p>
                </div>
              </Col>
            </div>

            <div className="col-lg-3 service">
              <p>Uitlezen</p>
              <hr />
              <Col id="wrap-1">
                <img src={Uitlezen} className="img-fluid" alt="uitlezen" />
                <div class="overlay">
                  <p>Vanaf €41,32</p>
                </div>
              </Col>
            </div>

            <div className="col-lg-3 service">
              <p>Onderzoek</p>
              <hr />
              <Col id="wrap-1">
                <img src={Onderzoek} className="img-fluid" alt="onderzoek" />
                <div class="overlay">
                  <p>Vanaf €75,-</p>
                </div>
              </Col>
            </div>
          </Row>
        </div>
        <Col lg={5} className="col-lg-5 mx-auto mb-4 py-3 centered-content">
          <Link to="/tarieven" exact>
            <Button className="btn-on-white py-2">
              Bekijk alle services en tarieven
            </Button>
          </Link>
        </Col>
      </div>

      <div>
        <Col lg={8} className="mx-auto my-3 py-3 ad">
          <Jumbotron>
            <Col lg={12} className="ml-lg-5">
              <h3>Bent u tevreden met onze service?</h3>
              <p>Laat een review achter</p>
            </Col>
            <Col lg={4} className="flex btn-wrap-right mr-lg-5">
              <a
                href="https://search.google.com/local/writereview?placeid=ChIJrTBIR7s0xEcR6xrLS_mDS5Q"
                target="blank"
              >
                <Button className="btn-blue">Review achterlaten</Button>
              </a>
            </Col>
          </Jumbotron>
        </Col>
      </div>
    </div>
  );
}

export default Home;
