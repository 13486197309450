import React from 'react';
import '../App';
import { Fade, Slide } from 'react-reveal';
import { Col, Container, Image } from 'react-bootstrap';

function Home() {
  return (
  
      <div className="Home">
        <Slide top duration={1600}>
          <Col lg={12} className="header home">
            <Col lg={12} className="header-inner">
              <h1 className="header-text">MB Garant</h1>
              <p className="header-text">
                reparatie | onderhoud | service | diagnose | apk
              </p>
            </Col>
          </Col>
        </Slide>
      {/*inside**/}
      <Fade duration={800} delay={700}>
          <Container className="Container inside">
            <Col lg={11} className={'mx-auto'}>
              <div>
                <h2>Uw Mercedes-Benz Specialist</h2>
              </div>
              <hr/>
            </Col>
            
            
            
          </Container>
        </Fade>
      </div>
    
  );
}

export default Home;
