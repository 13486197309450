import React from 'react';
import './../App.css'
import logo from '../assets/images/svg/mini-logo.svg';
import Menu from '../assets/images/svg/menu.svg';
import { NavLink, Link } from 'react-router-dom';
import { Col, Container, Navbar, Dropdown } from 'react-bootstrap';
import { Fade } from 'react-reveal';
import Hidden from '@material-ui/core/Hidden';

function Nav(){
    return (
        <Navbar className='Nav mx-0 w-auto' sticky={'top'}>
            <Container fluid='true' className="m-auto" >
                <nav className='mx-auto'>
                    <Col>
                        <Link to='/' exact className='nav-left nav-left-text'>
                            <img src={logo} alt='logo' id='logo'/>
                        </Link>
                    </Col>

                    <Hidden smDown>
                        <div className='nav-right'>
                            <ul className="nav-links my-auto d-flex" activeclassname="active">
                                <NavLink className="nav-link" to='/' exact>
                                    <li>
                                        Home
                                    </li>
                                </NavLink>

                                <NavLink className="nav-link" to='/contact' activeclassname="active">
                                    <li>
                                        Contact
                                    </li>
                                </NavLink>
{/*}
                                <NavLink className="nav-link" to='/ons' activeclassname="active">
                                    <li>
                                        Over ons
                                    </li>
                                </NavLink>
    */}
                                <NavLink className="nav-link" to='/galerij' activeclassname="active">
                                    <li>
                                        Galerij
                                    </li>
                                </NavLink>

                                <NavLink className="nav-link" to='/tarieven' activeclassname="active">
                                    <li>
                                        Tarieven
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                    </Hidden>

                    <Hidden mdUp>
                        <div className='nav-right-mob mx-auto'>
                            <Dropdown>
                                <Dropdown.Toggle>
                                    <img src={Menu} alt='Menu'/>
                                </Dropdown.Toggle>
                                
                                <Dropdown.Menu>
                                    <Fade> 
                                        <Dropdown.Item as="button">
                                            <NavLink className="nav-link" to='/' exact>
                                                Home
                                            </NavLink>
                                        </Dropdown.Item>

                                        <Dropdown.Item as="button">
                                            <NavLink className="nav-link" to='/contact' activeclassname="active-mobile">
                                                Contact
                                            </NavLink>
                                        </Dropdown.Item>

                                        {/*
                                        <Dropdown.Item as="button">
                                            <NavLink className="nav-link" to='/ons'>
                                                    Over ons
                                            </NavLink>
                                        </Dropdown.Item>
                                        */}
                                        
                                        <Dropdown.Item as="button">
                                            <NavLink className="nav-link" to='/gallerij' activeclassname="active-mobile"> 
                                                Gallerij
                                            </NavLink>                                  
                                        </Dropdown.Item>

                                        <Dropdown.Item as="button">
                                            <NavLink className="nav-link" to='/tarieven' activeclassname="active-mobile">
                                                Tarieven
                                            </NavLink>                                  
                                        </Dropdown.Item>
                                    </Fade>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Hidden>
                </nav>
            </Container>
        </Navbar>
        
    );
}

export default Nav;