import React from 'react';
import './../App.css'
import logo from '../assets/images/svg/full-logo.svg';
import Insta from '../assets/images/svg/instagram-brands.svg';
import Facebook from '../assets/images/svg/facebook-square-brands.svg';
import Location from '../assets/images/svg/map-marker-alt-solid.svg';
import Phone from '../assets/images/svg/phone-alt-solid.svg';
import { Col, Container, Row } from 'react-bootstrap';
import Hidden from '@material-ui/core/Hidden';
import { Link } from 'react-router-dom';

function Footer(){
    return (
        <div className='Footer'>
            <Col lg={11} fluid className='pt-4 mx-auto'>
                <Hidden smDown>
                    <Container fluid>
                        <Col lg={12} className='footer-row d-flex'>
                            <Col lg={"auto"} className='b-0 blogo footer-item'>
                                    <img src={logo} alt='logo' />
                            </Col>

                            <Col lg={"auto"} className='footer-item footer-text'>
                                <div classname="px-3">
                                    <ul className='footer-list'>
                                        <li>
                                            <a href='https://www.google.com/maps/place/MB+Garant/@51.9224144,4.4613582,17z/data=!3m1!4b1!4m5!3m4!1s0x47c434bb474830ad:0x944b83f94bcb1aeb!8m2!3d51.9224144!4d4.4635469' target="blank">
                                                <h6>
                                                    <img src={Location} className='footer-icon' alt='Location'/>&nbsp; 
                                                    Weena 966, 3014 AX Rotterdam  
                                                </h6>
                                            </a>
                                            
                                        </li>
                                        <li>
                                            <img src={Phone} className='footer-icon' alt='Phone'/>&nbsp; 010 - 341 03 62
                                        </li>
                                        <li>
                                            &nbsp;
                                        </li>
                                    </ul>
                                </div>
                                
                                
                            </Col>

                            <Col lg={2} className='footer-item footer-text'>
                                <ul className='footer-list pt-0'>
                                    {/*<li>
                                        <Link to='/ons' exact>Over ons</Link>
                                    </li>*/}
                                    <li>
                                        <a>
                                            <Link to='/contact' exact>Contact</Link>   
                                        </a>
                                    </li>
                                    <li>
                                        <a>
                                        <Link to='/tarieven' exact>Tarieven</Link> 
                                        </a> 
                                    </li>
                                    <li>
                                        <a>
                                            <Link to='/contact' exact>Openingstijden</Link>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </Col>

                            <Col lg={2} className='footer-item footer-text'>
                                <ul className='footer-list'>
                                    <li>
                                        Volg ons op
                                    </li>

                                    <li>
                                        <a href='https://www.instagram.com/mb_garant/' target="blank">
                                            <img src={Insta} className='mr-1 footer-icon hover' alt='Instagram'/>
                                        </a>
                                        
                                        <a href='https://nl-nl.facebook.com/MBGarant966/' target="blank">
                                            <img src={Facebook} className='m-1 footer-icon hover' alt='Facebook'/>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </Col>
                        </Col>

                        <Row className='footer-row'>
                            <div className='col-lg-8 footer-item text-center mx-auto b-0 pt-3'>
                                <p className=' small'>
                                    © MB Garant - Alle rechten voorbehouden
                                </p>
                            </div>
                        </Row>
                    </Container>
                </Hidden>

                <Hidden mdUp>
                    <Row className='footer-row'>
                        <p className='sub-text my-auto pb-4 text-center'>
                            © MB Garant - Alle rechten voorbehouden
                        </p>
                    </Row>
                </Hidden>
            </Col>
        </div>
    );
}

export default Footer;