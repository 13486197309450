import React from 'react';
import '../App';
import { Fade, Slide } from 'react-reveal';
import { Col, Container, Carousel, Button } from 'react-bootstrap';
import Carousel1 from '../assets/images/e-class-amg-background.jpg';
import ItemsCarousel from 'react-items-carousel';
import range from 'lodash/range';
import Hidden from '@material-ui/core/Hidden';
import Left from '../assets/images/svg/chevron-left-solid.svg';
import Right from '../assets/images/svg/chevron-right-solid.svg';

export default class  Gallery extends React.Component {
  componentWillMount() {
    this.setState({
      children: [],
      activeItemIndex: 0,
    });

    setTimeout(() => {
      this.setState({
      })
    }, 100);
  }
  createChildren = n => range(n).map(i => <div key={i} style={{ height: 200, background: '#333' }}>{i}</div>);

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });

  render() {
    const {
      activeItemIndex,
    } = this.state;
  return (
    <div className="Gallery">
      <Slide top duration={1600}>
        <Col lg={12} className="header gallery">
          <Col lg={12} className="header-inner">
            <h1 className="header-text">Galerij</h1>
            <p className="header-text">
              Bekijk hier onze foto's.
            </p>
          </Col>
        </Col>
      </Slide>

      {/*inside**/}
      <Fade duration={800} delay={700}>
        <Container className="Container inside">
          <Col lg={11} className={'mx-auto'}>
            <div>
              <h2>Uitgelicht</h2>
            </div>
            <hr/>
          </Col>

          <Col className="mx-auto">
            <Carousel className="top-carousel">
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../assets/images/1694.png")}
                alt="First slide"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../assets/images/4321.png")}
                alt="Third slide"
              />
            </Carousel.Item>

            <Carousel.Item>
              <img
                className="d-block w-100"
                src={require("../assets/images/1693.png")}
                alt="Third slide"
              />
            </Carousel.Item>
          </Carousel>
          </Col>
          
          <Hidden xsDown>
            <Col lg={9} className="py-lg-5 py-sm-2 mx-auto">
              <hr/>
            </Col>
          </Hidden>
        </Container>  
      </Fade>

    <Hidden xsDown>  
      <div className="bottom-carousel px-5 py-4">
        <ItemsCarousel
            // Placeholder configurations
            enablePlaceholder
            numberOfPlaceholderItems={5}
            minimumPlaceholderTime={1000}
            placeholderItem={<div style={{ height: 200, background: '#900' }}>Placeholder</div>}

            // Carousel configurations
            numberOfCards={3}
            gutter={12}
            showSlither={true}
            firstAndLastGutter={true}
            freeScrolling={false}

            // Active item configurations
            requestToChangeActive={this.changeActiveItem}
            activeItemIndex={activeItemIndex}
            activePosition={'center'}

            chevronWidth={100}
            rightChevron={<img src={Right}/>}
            leftChevron={<img src={Left}/>}
            outsideChevron={false}
          >
            <img
                className="w-100"
                src={require("../assets/images/555.jpg")}
                alt="First slide"
            />
            <img
            className="w-100"
            src={require("../assets/images/222.jpg")}
            alt="First slide"
            />
            <img
            className="w-100"
            src={require("../assets/images/333.jpg")}
            alt="First slide"
            />
            <img
                className="w-100"
                src={require("../assets/images/544.jpg")}
                alt="First slide"
            />
            <img
            className="w-100"
            src={require("../assets/images/4474.jpg")}
            alt="First slide"
            />
            <img
            className="w-100"
            src={require("../assets/images/3333.jpg")}
            alt="First slide"
            />
          </ItemsCarousel>
      </div> 
    </Hidden>
    <Col lg={4} className="mx-auto py-4 mb-3 centered-content">
      <a href='https://www.instagram.com/mb_garant/' target="blank">
        <Button className='btn-on-white'>Bekijk meer van onze foto's op instagram</Button>
      </a>
    </Col>
      
    </div>
    
  );}}
