import React from "react";
import "../App";
import { Fade, Slide } from "react-reveal";
import { Col, Container, Row, Table } from "react-bootstrap";

function Tarieven() {
  return (
    <div className="Tarieven">
      <Slide top duration={1600}>
        <Col lg={12} className="header tarieven">
          <Col lg={12} className="header-inner">
            <h1 className="header-text">Tarieven</h1>
          </Col>
        </Col>
      </Slide>

      {/*inside**/}
      <Fade duration={800} delay={700}>
        <Container className="Container inside">
          <Col lg={11} className={"mx-auto"}>
            <div>
              <Row>
                <Col>
                  <hr className="thicc mx-0" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <h2>Goed om te weten</h2>
                  <p>
                    Elke auto is uniek, ook die van u.
                    <br />
                    Wij willen u er daarom op attenderen dat de service kosten
                    per automodel kunnen verschillen.
                  </p>
                  <p>
                    Houd uw kenteken en chassisnummer bij
                    de hand wanneer u onderdelen wilt bestellen. Speciaal voor u
                    bestelde delen en arbeidskosten dienen vooraf te worden
                    afgerekend. Speciaal bestelde en elektrische onderdelen
                    nemen wij niet retour, bedankt voor u begrip en medewerking.
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          <Row>
            <Col lg={11} md={6} className="mx-auto pt-5 mt-4">
              <Table className={"price-table"}>
                <tr>
                  <td>Uurtarief arbeidskosten</td>
                  <td>
                    <Row>
                      <h3>€125,-</h3>
                    </Row>
                    <Row>€151,25 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Uitlezen</td>
                  <td>
                    <Row>
                      <h3>€60,-</h3>
                    </Row>
                    <Row>€72,60 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Onderzoekskosten</td>
                  <td>
                    <Row>
                      <h3>€100,-</h3>
                    </Row>
                    <Row>€121,- incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>SCN Coderen</td>
                  <td>
                    <Row>
                      <h3>€120,-</h3>
                    </Row>
                    <Row>€145,20 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>APK Benzine</td>
                  <td>
                    <Row>
                      <h3>€50,-</h3>
                    </Row>
                    <Row>€60,50 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>APK Diesel</td>
                  <td>
                    <Row>
                      <h3>€100,-</h3>
                    </Row>
                    <Row>€121,- incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Remvloeistof</td>
                  <td>
                    <Row>
                      <h3>€90,-</h3>
                    </Row>
                    <Row>€108,90 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Motorolie 5w30 229.52</td>
                  <td>
                    <Row>
                      <h3>€17,50</h3>
                    </Row>
                    <Row>€21,18 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Motorolie 5w40 229.5</td>
                  <td>
                    <Row>
                      <h3>€15,50</h3>
                    </Row>
                    <Row>€18,76 incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Stallingskosten</td>
                  <td>
                    <Row>
                      <h3>€30,- p/d</h3>
                    </Row>
                    <Row>€36,30 p/d incl.</Row>
                  </td>
                </tr>
                <tr>
                  <td>Taxatiekosten</td>
                  <td>
                    <Row>
                      <h3>€300,-</h3>
                    </Row>
                    <Row>€363,-</Row>
                  </td>
                </tr>
              </Table>
            </Col>
          </Row>

          <Col
            lg={6}
            className={"mx-auto pt-2 small text-center sub-text"}
          ></Col>
        </Container>
      </Fade>
    </div>
  );
}

export default Tarieven;
