import React from 'react';
import '../App';
import { Fade, Slide } from 'react-reveal';
import { Col, Container, Row, Table, Image, Jumbotron } from 'react-bootstrap';
import Insta from '../assets/images/icons/instagram-brands.png';
import Facebook from '../assets/images/icons/facebook-square-brands.png';
import Location from '../assets/images/icons/map-marker-alt-solid.png';
import Phone from '../assets/images/icons/phone-alt-solid.png';
import Mail from '../assets/images/icons/envelope-open-text-solid.png';
import Hidden from '@material-ui/core/Hidden';

function Contact() {
  return (
    <div className="Contact">
      <Slide top duration={1600}>
        <Col lg={12} className="header contact">
          <Col lg={12} className="header-inner">
              <h1 className="header-text">
                Contact
              </h1>
          </Col>
        </Col>
      </Slide>

      {/*inside**/}
      <Fade duration={800} delay={200}>
        <Container className="Container inside contact-inner">
          <Col lg={11} className={'mx-auto'}>
            <div>
              <Row>
                <Col>
                  <hr className='thicc mx-0'/>
                </Col>
              </Row>
              <Row> 
                <Col>
                  <h2>Contactinformatie</h2>
                  <p>
                    MB Garant is gevestigd aan de Weena in Rotterdam Centrum.<br/> 
                    Bij ons kunt u zes dagen per week terecht voor onderhoud en reparatie aan uw auto. <br/>
                    <a href={{javascript:void(0)}} title="zie tijden" onClick={e => {
                      let tijd = document.getElementById("openingstijden");
                      e.preventDefault();  // Stop Page Reloading
                      tijd && tijd.scrollIntoView({ behavior: "smooth", block: "start" });
                    }}>
                      Zie openingstijden
                    </a>
                  </p>
                </Col>
              </Row>
            </div>
            
            <Col lg={7}>
              <ul className='info'>
                <Hidden xsDown>
                <li>
                  <Image fluid src={Phone} className='footer-icon pr-2' alt='Phone'/>&nbsp;&nbsp;010 - 341 03 62
                </li>
                </Hidden>
                <Hidden smUp>
                <li>
                  <a target="_blank" id="action-list-1" class="dtKbfb  oYxtQd" href="tel:+31103410362" data-tracking-element-type="2" jslog="56037; track:impression,click" itemprop="telephone" data-enable-ga="true" data-ga-prefix="action-list">
                  <Image fluid src={Phone} className='footer-icon pr-2' alt='Phone'/></a>&nbsp;&nbsp;<a target="_blank" id="action-list-1" class="dtKbfb  oYxtQd" href="tel:+31103410362" data-tracking-element-type="2" jslog="56037; track:impression,click" itemprop="telephone" data-enable-ga="true" data-ga-prefix="action-list">010 - 341 03 62</a>
                </li>
                </Hidden>
                <li>
                  <a href="mailto:mbgarant@outlook.com">
                    <Image fluid src={Mail} className='footer-icon pr-2' alt='Mail'/></a>&nbsp;&nbsp;<a href="mailto:mbgarant@outlook.com">mbgarant@outlook.com
                  </a>
                </li>
                <li>
                  <a href='https://www.google.com/maps/place/MB+Garant/@51.922476,4.463545,16z/data=!4m5!3m4!1s0x0:0x944b83f94bcb1aeb!8m2!3d51.9224756!4d4.4635446?hl=en' target='blank'>
                    <Image fluid src={Location} className='footer-icon pr-2' alt='Location'/>
                  </a>&nbsp;&nbsp;
                  <a href='https://www.google.com/maps/place/MB+Garant/@51.922476,4.463545,16z/data=!4m5!3m4!1s0x0:0x944b83f94bcb1aeb!8m2!3d51.9224756!4d4.4635446?hl=en' target='blank'>
                   Weena 966, 3014 AX Rotterdam</a>
                </li>
              </ul>
            </Col>

            <Col>
              <hr/>
              <Col lg={6} className='mx-auto sub'>
              <a href='https://www.instagram.com/mb_garant/' target="blank">
                <Image fluid src={Insta} className='footer-icon' alt='Instagram'/></a>
                <a href='https://nl-nl.facebook.com/MBGarant966/' target="blank">
                <Image fluid src={Facebook} className='footer-icon' alt='Facebook'/></a>
              </Col>
              
            </Col>
          </Col>

          <Col lg={6} className='mx-auto text-center'>
            <ul>
              
            </ul>
          </Col>
        </Container>
        <Row>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2460.632574253925!2d4.4613582157853005!3d51.92241437970551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c434bb474830ad%3A0x944b83f94bcb1aeb!2sMB+Garant!5e0!3m2!1sen!2snl!4v1565740302771!5m2!1sen!2snl" frameBorder='0' title='location' target="blank" id='map'/>   
        </Row>
        
        <Row className="mx-auto">
          <Col lg={6} id="openingstijden" className='timetable pb-5 pt-3'>
            
            <h2>Openingstijden</h2>
            <hr/>
            <Col >
              <Table>
                <tr>
                  <td>Maandag</td>
                  <td>9.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Dinsdag</td>
                  <td>9.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Woensdag</td>
                  <td>9.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Donderdag</td>
                  <td>9.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Vrijdag</td>
                  <td>9.00 - 17.00</td>
                </tr>
                <tr>
                  <td>Zaterdag</td>
                  <td>9.00 - 16.00</td>
                </tr>
                <tr>
                  <td>Zondag</td>
                  <td>gesloten</td>
                </tr>
              </Table>
            </Col>
          </Col>
        </Row>
      </Fade>
    </div>
  );
}

export default Contact;
